




import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "CareerPage"
})
export default class CareerPage extends Vue {}
